.portfolio .item {
    height: 420px;
    position: relative;
    padding: 0 40px;
    cursor: pointer;
    transition: var(--transition);
}

#portfolio {
    background: url("../../assets/images/5.png") center/cover no-repeat;
    z-index: 2;
    height: 100%;

}

.portfolio .item * {
    z-index: 1;
    opacity: 0;
    /* Set initial opacity to 0 for all screens */
    transition: var(--transition);
}

.portfolio .item::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: navy;
    left: 0;
    top: 0;
    opacity: 0;
    /* Set initial opacity to 0 for all screens */
    transition: var(--transition);
}

.portfolio .item-title {
    border-bottom: 1px solid var(--clr-white);
    padding-bottom: 20px;
}

.portfolio .item-list {
    margin-top: 40px;
    row-gap: 40px;
}

.portfolio .item:hover * {
    opacity: 1;
}

.portfolio .item:hover::after {
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .portfolio .item-list {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;
    }

    .portfolio .section-t {
        text-align: center;
    }

    .portfolio .section-t::before {
        left: 50%;
        transform: translateX(-50%);
    }

    .portfolio .item-list {
        grid-template-columns: repeat(3, 1fr);
    }
}