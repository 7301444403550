@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

#contact {
  background: url("../../assets/images/3.png") center/cover no-repeat;
  background-size: cover;
  background-position: center;
}

.contact {
  width: 100%;

  background-size: cover;
  background-position: top;
  padding: 0%;
  font-family: 'Montserrat', sans-serif;
}

.contact h1 {
  text-align: center;
  color: white;
  font-size: 30px;
  margin: 0%;
  letter-spacing: 0.05rem;
}

.contact h3 {
  text-align: center;
  color: rgb(211, 211, 211);
  font-size: 20px;
  margin: 2%;
  margin-bottom: 2%;
  letter-spacing: 0.05rem;
}

.contact span {
  color: rgb(211, 211, 211);
}

.contact h4 {
  text-align: center;
  color: white;
  font-size: 20px;
  margin: 2%;
  margin-bottom: 4%;
  letter-spacing: 0.15rem;
}

.contact .cards {
  border-radius: 20px;
  padding: 2% 0;
  overflow: hidden;
  margin-top: -40px;
}

.contact .inner-card {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 40vh;
  align-items: center;
}

.contact .card {
  background: url("../../assets/images/ca-portal.png") center/cover no-repeat;
  height: 320px;
  margin: 1vw;
  transition: transform 0.3s ease-in-out;
  width: 20%;
  z-index: 1;
}

.contact .card:hover {
  transform: scale(1.03);
}

.card .image {
  width: 200px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  margin: 20px auto;
  border-radius: 50%;
  margin-bottom: 0;
}

.card .image img {
  object-fit: cover;
  object-position: top;
}

.card .text {
  margin-top: 10px;
  text-align: center;
  color: rgb(211, 211, 211);
}

.card .text .name {
  font-size: 18px;
  margin: 6px 0;
}

.card .text .no {
  font-size: 12px;
  margin: -1px;
}

i {
  font-size: 12px;
}

.card .text .email {
  font-size: 18px;
  margin-bottom: 3px;
}

.card .fa {
  font-size: 20px;
  margin-right: 7px;
}

.card .links {
  height: 80px;
}

.card .links {
  color: rgb(211, 211, 211);
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links a {
  padding: 0 5px !important;
}

.card .links .fa {
  padding: 0;
  margin: 0 3%;
  font-size: 1.9rem;
}

.card .links a {
  color: rgb(211, 211, 211);
}

.fa-facebook:hover {
  color: #3b5998;
}

.fa-linkedin:hover {
  color: #0077b5;
}

@media screen and (max-width: 768px) {
  .contact .card {
    width: 90%;
    margin-top: 10px;
  }

  .contact h3 {
    font-size: 30px;
  }
}