/* Modern Gradient Background */
#footer {
    background: linear-gradient(135deg, #062a6c, #04255f); /* Modern gradient */
    background-size: cover;
    background-position: center;
    color: white; /* Consistent white text */
}

/* Remove Underline and Set Hover Colors for Links */
a:link,
a:visited,
a:active {
    text-decoration: none;
    color: #ffffff;
    transition: color 0.1s ease;
}

a:hover {
    text-decoration: underline;
    color: #394bd1; /* Gold hover color */
}

/* Footer About Section */
.footer-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 60%;
    margin: 0 auto;
    font-size: 1rem; /* Adjust text size */
    line-height: 1.6;
}

/* Social Icons Section */
.footer-social--icons {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Space between icons */
    margin: 1.5rem 0;
}

.icon {
    font-size: 2.5rem; /* Adjust size for a modern look */
    transition: transform 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

/* Icon Hover Effects */
.icons:hover {
    transform: scale(1.2); /* Smooth zoom-in effect */
}

/* Specific Colors for Each Social Media Platform */
.ifb:hover {
    color: #4267B2; /* Facebook Blue */
}

.iinsta:hover {
    color: #E1306C; /* Instagram Pink */
}

.iyt:hover {
    color: #FF0000; /* YouTube Red */
}

.itwit:hover {
    color: #0077B5; /* LinkedIn Blue */
}

/* Copyright Section */
.footer-copyright-box {
    border: 1px solid white;
    padding: 1rem;
    border-radius: 8px; /* Rounded corners */
    background: rgba(255, 255, 255, 0.1); /* Transparent background */
    text-align: center;
    font-size: .9rem;
    position: relative;
    bottom: 20px;
    margin-top: 20px;
}

/* Footer Component Styles */
.footer_component footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    text-align: center;
    position: relative;
}

.footer_component p {
    font-size: 3rem;
    margin-top: 1rem;
    line-height: 1.4;
    opacity: 0.8; /* Subtle opacity for better visuals */
}

/* Typography */
.footer_component h3 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
    .footer-about {
        max-width: 90%;
    }

    .footer-social--icons {
        flex-direction: column;
        gap: 1rem;
    }

    .icon {
        font-size: 2rem; /* Smaller icons on mobile */
    }

    .footer-copyright-box {
        font-size: 0.8rem;
        padding: 0.8rem;
    }

    .footer_component p {
        font-size: 0.9rem;
    }
}
