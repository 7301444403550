.navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 78px;
    background-color: var(--clr-black);
    z-index: 999;
}

.navbar-brand {
    color: var(--clr-white);
}

.hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 26px;
    width: 34px;
    margin-right: 20px;
    cursor: pointer;
    z-index: 10;
}

.bar-top,
.bar-middle,
.bar-bottom {
    height: 3px;
    background-color: var(--clr-white);
    transform-origin: left;
    transition: var(--transition);
}

.hamburger-menu-change .bar-top {
    transform: rotate(42deg);
}

.hamburger-menu-change .bar-middle {
    transform: translateX(1rem);
    opacity: 0;
}

.hamburger-menu-change .bar-bottom {
    transform: rotate(-42deg);
}

.logo-image {
    width: 200px;
    /* Adjust the width as needed */
    margin-left: 10px;
    margin-right: 10px;
    /* Adjust the left margin as needed */
}

.navbar-collapse {
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--clr-dark);
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateX(100%);
    transition: var(--transition);
}

.show-navbar-collapse {
    transform: translateX(0);
}

.navbar-collapse-content {
    padding: 40px;
}

.navbar-nav {
    margin-bottom: 30px;
}

.navbar-nav li {
    margin: 20px 0;
    font-size: 2rem;
    transition: var(--transition);
}

.navbar-nav li:hover {
    color: var(--clr-black);
}

.navbar-social li {
    margin-right: 12px;
    font-size: 2.4rem;
}

.navbar-btns {
    margin-top: 2.4rem;
}

.navbar-btns button {
    border: 2px solid var(--clr-robin-blue);
    transition: var(--transition);
}

.navbar-btns button span {
    margin-left: 8px;
}

.navbar-btns button:hover {
    background-color: var(--clr-robin-blue);
}

@media screen and (min-width: 578px) {
    .navbar-collapse {
        width: 320px;
    }
}

@media screen and (min-width: 992px) {
    .navbar-collapse {
        transform: translateX(0);
        position: relative;
        background-color: transparent;
        box-shadow: none;
        top: 0;
        height: auto;
        width: 100%;
    }

    .navbar-collapse-content {
        padding: 0;
        display: flex;
        align-items: center;
    }

    .hamburger-menu {
        display: none;
    }

    .navbar-nav {
        display: flex !important;
        margin-bottom: 0;
    }

    .navbar-btns {
        margin-top: 0;
        margin-left: 28px;
    }

    .navbar-nav li {
        margin: 0;
        font-size: 1.9rem;
        font-weight: 500;
        margin-left: 36px;
    }

    .navbar-nav li:hover {
        color: var(--clr-crazy-blue);
    }

    .navbar-social {
        margin-left: 60px;
    }

    .navbar-social li {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
    }

    .navbar-social li:hover {
        color: var(--clr-crazy-blue);
    }
}

.button1 {
    cursor: pointer;
    outline: 0;
    border: none;
    background-color: black;
    font-family: inherit;
    font-size: 1.8rem;
}